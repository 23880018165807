import { Grid, Button, Typography, Box } from '@mui/material'
import Logo from 'src/Assets/Images/logo_white.svg'
import Done from 'src/Assets/Icons/done.svg'
import styles from './index.module.scss'
import { APPLICATION_STEPS, ApplicationStepsType } from 'src/Utils/Constants'
import { useNavigate } from 'react-router-dom'
import { HasAssistant } from '../CustomApplication'

const SideSteps = ({ active }: { active: string }) => {
  const assistant = HasAssistant()
  const actNo = APPLICATION_STEPS.findIndex((key) => key.routes === active)
  const navigate = useNavigate()
  return (
    <Grid item xs={12} className={styles.container}>
      <img className={styles.logo} alt='Logo' src={Logo} key='logo' />
      <Grid item xs={12} className={styles.main}>
        {APPLICATION_STEPS.map((step: ApplicationStepsType, index: number) => (
          <>
            {index !== 0 && (
              <Box className={styles.lineWrapper}>
                <span className={styles.linex}></span>
              </Box>
            )}
            <Button
              className={styles.element}
              onClick={() => {
                navigate(step.routes)
              }}
            >
              <Grid
                item
                xs={12}
                className={
                  active === step.routes ? styles.counter : styles.divWrapper
                }
              >
                <Typography className={styles.textWrapper}>
                  {actNo <= index ? index + 1 : <img src={Done} alt='v' />}
                </Typography>
              </Grid>
              <Grid item xs={12} className={styles.title}>
                <Typography className={styles.div}>{step.label}</Typography>
                <Typography className={styles.textWrapper2}>
                  {assistant ? step.description : step.descriptionyou}
                </Typography>
              </Grid>
            </Button>
          </>
        ))}
      </Grid>
    </Grid>
  )
}

export default SideSteps
