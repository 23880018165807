import { Box, Grid, Button, InputLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import { ApplicationIntroBox, StarText, Calander } from 'src/Components/Fields'
import { ChildCareType } from 'src/Redux/Services/Types/applicationType'
import { EditChildCare } from 'src/Redux/Slices/ApplicationSlices/EducationAndEmploymentSlice'
import { setChildCare } from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../index.module.scss'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { HasAssistant } from '../..'

const ChildCare = ({
  chatForm,
  handleNext,
  handleBack,
}: {
  chatForm: any
  handleNext: () => void
  handleBack: () => void
}) => {
  const assistant = HasAssistant()
  const dispatch = useAppDispatch()

  const storeValues = useAppSelector(
    (state) => state?.applicationStore?.EducationAndEmployement?.ChildCare,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const educationId = useAppSelector(
    (state) =>
      state?.applicationStore?.ApplicationId?.EducationAndEmployementId,
  )
  const [formValues, setFormValues] = useState<ChildCareType>({
    educationId,
    fulltimeChildcareUnder3Years: undefined,
    fulltimeChildcareStartYear: '',
    fulltimeChildcareEndYear: '',
  })
  const [errors, setErrors] = useState<Record<string, string>>({})

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({ ...storeValues, educationId })
    setErrors({})
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            fulltimeChildcareUnder3Years:
              formValues?.fulltimeChildcareUnder3Years === undefined
                ? 'Boolean'
                : formValues?.fulltimeChildcareUnder3Years,
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  const handleChange = (data: { name: string; value?: string | boolean }) => {
    setFormValues({ ...formValues, [data.name]: data.value })
    setErrors({ ...errors, [data.name]: '' })
  }

  const validation = () => {
    let isValid = true
    const newErrors: Record<string, string> = {}
    const currentDate = new Date().toISOString().split('T')[0]
    if (formValues.fulltimeChildcareUnder3Years === undefined) {
      newErrors.fulltimeChildcareUnder3Years = 'Please select an option'
      isValid = false
    }
    if (formValues.fulltimeChildcareUnder3Years) {
      if (!formValues.fulltimeChildcareStartYear?.trim()) {
        newErrors.fulltimeChildcareStartYear = 'Please select a date'
        isValid = false
      }
      if (formValues.fulltimeChildcareStartYear > currentDate) {
        newErrors.fulltimeChildcareStartYear = 'Please select valid date'
        isValid = false
      }
      if (!formValues.fulltimeChildcareEndYear?.trim()) {
        newErrors.fulltimeChildcareEndYear = 'Please select a date'
        isValid = false
      }
      if (formValues.fulltimeChildcareEndYear > currentDate) {
        newErrors.fulltimeChildcareEndYear = 'Please select valid date'
        isValid = false
      }
    }

    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validation()) {
      const payload: ChildCareType = {}
      payload.educationId = formValues.educationId
      payload.fulltimeChildcareUnder3Years =
        formValues.fulltimeChildcareUnder3Years
      if (formValues.fulltimeChildcareUnder3Years) {
        payload.fulltimeChildcareStartYear =
          formValues.fulltimeChildcareStartYear
        payload.fulltimeChildcareEndYear = formValues.fulltimeChildcareEndYear
      }
      dispatch(setChildCare(formValues))
      dispatch(EditChildCare(payload)).then((res) => {
        if (res?.payload?.success) {
          handleNext()
        }
      })
    }
  }

  return (
    <>
      <Box className={Style.DialogContentBox}>
        <Box className={Style.GridBox}>
          <form className={Style.Form} onSubmit={handleSubmit}>
            <ApplicationIntroBox
              heading={`Great work so far! Now, we're going to gather details about childcare that may have impacted ${assistant ? `applicant's` : `your`} ability to work.`}
            />
            <CommonRadioBox
              title={
                assistant
                  ? 'Has applicant cared for children under the age of three (3) full-time?'
                  : 'Have you cared for children under the age of three (3) full-time?'
              }
              YesTitle='I have cared for children under the age of three (3) full-time.'
              NoTitle='I have not cared for children under the age of three (3) full-time.'
              commonState={formValues.fulltimeChildcareUnder3Years}
              setcommonState={(value) => {
                handleChange({ name: 'fulltimeChildcareUnder3Years', value })
              }}
              error={errors.fulltimeChildcareUnder3Years}
            />
            {formValues.fulltimeChildcareUnder3Years ? (
              <>
                <StarText
                  text={`We're trying to understand if this was ${assistant ? `applicant's` : `your`} main responsibility in past years and no earnings were made`}
                />
                <Box height={'40px'} width={'100%'}></Box>
                <InputLabel className={Style.label}>
                  Wonderful, we understand taking care of young kiddos is tough
                  but rewarding work. Let us know the years $
                  {assistant ? `applicant` : `you`} cared for children under
                  three (3) full-time?
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Calander
                    name='fulltimeChildcareStartYear'
                    value={formValues.fulltimeChildcareStartYear}
                    handleChange={handleChange}
                    error={errors.fulltimeChildcareStartYear}
                    title='Year Started Caring for Children Under 3'
                  />
                  <Calander
                    name='fulltimeChildcareEndYear'
                    value={formValues.fulltimeChildcareEndYear}
                    handleChange={handleChange}
                    error={errors.fulltimeChildcareEndYear}
                    title='Year Ended Caring for Children Under 3'
                    minDate={formValues.fulltimeChildcareStartYear}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Box className={Style.AddButton}>
              <Button
                variant='contained'
                className={Style.ButtonOne}
                onClick={handleBack}
              >
                Previous
              </Button>
              <Button
                variant='contained'
                type='submit'
                className={Style.ButtonTwo}
              >
                Next
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default ChildCare
