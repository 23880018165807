import {
  Avatar,
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Delete from 'src/Assets/Icons/delete.svg'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import Edit from 'src/Assets/Icons/edit.svg'
import { employeeType } from 'src/Redux/Services/Types/employeeType'
import Style from './index.module.scss'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import {
  EditEmployeeRequest,
  EmployeeDetailsRequest,
} from 'src/Redux/Slices/employeeSlice'
import { IOSSwitch } from 'src/Theme'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'

const EditDetails: React.FunctionComponent<{
  employeeData: employeeType
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ employeeData, setIsEditing }) => {
  const dispatch = useAppDispatch()
  const rolelist = useAppSelector((state) => state.roles?.roleList?.data)
  const [removeProfileURL, setRemoveProfileURL] = React.useState(false)
  const [selectedFile, setSelectedFile] = useState(
    employeeData?.profileUrl ?? '',
  )
  const [previewUrl, setPreviewUrl] = useState(employeeData?.profileUrl)
  const [isActive, setIsActive] = useState(employeeData?.isActive)
  const [formValues, setFormValues] = useState<{
    firstName: string
    lastName: string
    emailId: string
    phoneNumber: string
    roleUUID: string
    jobTitle: string
    jobType: string
    organizationUUID: string
  }>({
    firstName: employeeData.firstName ?? '',
    lastName: employeeData.lastName ?? '',
    emailId: employeeData.emailId ?? '',
    phoneNumber: employeeData.phoneNumber ?? '',
    roleUUID: employeeData.roleUUID ?? '',
    jobTitle: employeeData.jobTitle ?? '',
    jobType: employeeData.jobType ?? '',
    organizationUUID: employeeData.organizationUUID ?? '',
  })
  const [errors, setErrors] = useState<Record<string, string>>()
  const [disableButton, setdisableButton] = useState<Boolean>(true)

  useEffect(() => {
    setFormValues({
      firstName: employeeData.firstName ?? '',
      lastName: employeeData.lastName ?? '',
      emailId: employeeData.emailId ?? '',
      phoneNumber: employeeData.phoneNumber ?? '',
      roleUUID: employeeData.roleUUID ?? '',
      jobTitle: employeeData.jobTitle ?? '',
      jobType: employeeData.jobType ?? '',
      organizationUUID: employeeData.organizationUUID ?? '',
    })
    setSelectedFile(employeeData?.profileUrl ?? '')
    setPreviewUrl(employeeData?.profileUrl)
  }, [employeeData])
  // Function to handle file selection
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setdisableButton(false)
    const file = event?.target?.files?.[0]
    if (file) {
      setRemoveProfileURL(false)
      setSelectedFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }
  // Function to handle removing the selected photo
  const handleRemovePhoto = () => {
    setSelectedFile('')
    setPreviewUrl('')
    setRemoveProfileURL(true)
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (!formValues?.firstName?.trim()) {
      newErrors.firstName = 'First Name is required'
      valid = false
    }
    if (!formValues?.lastName?.trim()) {
      newErrors.lastName = 'Last Name is required'
      valid = false
    }
    if (!formValues?.emailId?.trim()) {
      newErrors.emailId = 'Email Id is required'
      valid = false
    }
    if (!formValues?.phoneNumber?.trim()) {
      newErrors.phoneNumber = 'Phone number is required'
      valid = false
    }
    const phoneNumberRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
    if (!phoneNumberRegex.test(formValues?.phoneNumber?.trim())) {
      newErrors.phoneNumber = 'Invalid Phone number'
      valid = false
    }
    if (!formValues?.roleUUID?.trim()) {
      newErrors.roleUUID = 'Role Name is required'
      valid = false
    }
    if (!formValues?.jobType) {
      newErrors.jobType = 'Job Type is required'
      valid = false
    }

    setErrors(newErrors)
    return valid
  }
  const handleFormChange = (event: {
    target: { name: string; value: string | number }
  }) => {
    setdisableButton(false)
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const onDiscard = () => {
    setdisableButton(true)
    setSelectedFile(employeeData?.profileUrl ?? '')
    setPreviewUrl(employeeData?.profileUrl)
    setFormValues({
      firstName: employeeData.firstName ?? '',
      lastName: employeeData.lastName ?? '',
      emailId: employeeData.emailId ?? '',
      phoneNumber: employeeData.phoneNumber ?? '',
      roleUUID: employeeData.roleUUID ?? '',
      jobTitle: employeeData.jobTitle ?? '',
      jobType: employeeData.jobType ?? '',
      organizationUUID: employeeData.organizationUUID ?? '',
    })
  }
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (validateForm()) {
      const formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('firstName', formValues?.firstName)
      formData.append('lastName', formValues?.lastName)
      formData.append('emailId', formValues?.emailId)
      formData.append('phoneNumber', formValues?.phoneNumber)
      formData.append('roleId', formValues?.roleUUID)
      formData.append('jobTitle', formValues?.jobTitle)
      formData.append('jobType', formValues?.jobType)
      formData.append('organizationId', formValues?.organizationUUID)
      formData.append('isActive', `${isActive}`)
      formData.append('removeProfile', `${removeProfileURL}`)
      dispatch(
        EditEmployeeRequest({ data: formData, uuid: employeeData?.User_uuid }),
      ).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res.payload?.success) {
            setIsEditing(false)
          }
          setdisableButton(true)
          dispatch(EmployeeDetailsRequest(employeeData?.User_uuid))
        },
      )
    }
  }

  return (
    <Box component='div' className={Style.EditDetails}>
      <Box component='div' className={Style.titleText}>
        Employee Information
      </Box>
      <Box component='div'>
        <Box component='div' className={Style.EditContactImage}>
          <Box component='div'>
            <Box component='div'>
              <Box component='div' className={Style.IconEditWrapper}>
                <input
                  onChange={handleFileSelect}
                  id='profile-photo-upload'
                  type='file'
                  accept='image/jpeg, image/png'
                  style={{ cursor: 'pointer' }}
                  className={Style.inputFile}
                />
                <img src={Edit} className={Style.EditIcon} alt='Rectangle' />
              </Box>
              <Avatar
                sx={{ height: '90px', width: '90px', mt: '13px' }}
                src={previewUrl}
                alt='photoURL'
              />
              {(employeeData?.profileUrl ?? previewUrl) ? (
                <Box component='div' className={Style.IconDeleteWrapper}>
                  <img
                    src={Delete}
                    onClick={handleRemovePhoto}
                    alt='Rectangle'
                  />
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Box component='div' className={Style.Description}>
            <Typography className={Style.DescriptionP}>
              Allowed file types: png, jpg, jpeg.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={Style.flexSpaceBox}>
        <Box className={Style.fullWidth}>
          <Typography className={Style.textFieldWrapper}>
            First Name
            <Typography className={Style.FF002E}>*</Typography>
          </Typography>
          <TextField
            fullWidth
            variant='outlined'
            className={Style.field}
            placeholder=''
            value={formValues?.firstName}
            onChange={(e) => {
              handleFormChange(e)
            }}
            name='firstName'
            error={!!errors?.firstName}
            helperText={errors?.firstName}
          />
        </Box>
        <Box className={Style.fullWidth}>
          <Typography className={Style.textFieldWrapper}>
            Last Name
            <Typography className={Style.FF002E}>*</Typography>
          </Typography>
          <TextField
            fullWidth
            variant='outlined'
            className={Style.field}
            placeholder=''
            value={formValues?.lastName}
            onChange={(e) => {
              handleFormChange(e)
            }}
            name='lastName'
            error={!!errors?.lastName}
            helperText={errors?.lastName}
          />
        </Box>
      </Box>
      <Box className={Style.flexSpaceBox}>
        <Box className={Style.fullWidth}>
          <Typography className={Style.textFieldWrapper}>
            Employee Phone number
            <Typography className={Style.FF002E}>*</Typography>
          </Typography>
          <TextField
            fullWidth
            variant='outlined'
            className={Style.field}
            placeholder=''
            value={
              formValues?.phoneNumber
              // ? `(${formValues?.phoneNumber.slice(0, 3)}) ${formValues?.phoneNumber.slice(3, 6)}-${formValues?.phoneNumber.slice(6)}`
              // : '-'
            }
            onChange={(e) => {
              if (e.target.value.length <= 10 && /^\d*$/.test(e.target.value))
                handleFormChange(e)
            }}
            name='phoneNumber'
            error={!!errors?.phoneNumber}
            helperText={errors?.phoneNumber}
          />
        </Box>
        <Box className={Style.fullWidth}>
          <Typography className={Style.textFieldWrapper}>
            E-mail
            <Typography className={Style.FF002E}>*</Typography>
          </Typography>
          <TextField
            fullWidth
            variant='outlined'
            className={Style.field}
            placeholder=''
            disabled
            value={formValues?.emailId}
            onChange={(e) => {
              handleFormChange(e)
            }}
            name='emailId'
            error={!!errors?.emailId}
            helperText={errors?.emailId}
          />
        </Box>
      </Box>
      <Box className={Style.toggleWrapper}>
        <Box className={Style.toggleText}>
          <Typography className={Style.textFieldWrapper}>Active</Typography>
        </Box>
        <Box>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={isActive}
                onChange={() => {
                  setIsActive(!isActive)
                }}
              />
            }
            label=''
          />
        </Box>
      </Box>
      <div className={Style.dottedLine} />
      <Box component='div' className={Style.titleText}>
        Job Details
      </Box>
      <Box className={Style.flexSpaceBox}>
        <Box className={Style.fullWidth}>
          <Typography className={Style.textFieldWrapper}>
            Job Title
            <Typography className={Style.FF002E}>*</Typography>
          </Typography>
          <TextField
            fullWidth
            variant='outlined'
            className={Style.field}
            placeholder=''
            value={formValues?.jobTitle}
            onChange={(e) => {
              handleFormChange(e)
            }}
            name='jobTitle'
            error={!!errors?.jobTitle}
            helperText={errors?.jobTitle}
          />
        </Box>
        <Box className={Style.fullWidth}>
          <Typography className={Style.textFieldWrapper}>
            Job Type
            <Typography className={Style.FF002E}>*</Typography>
          </Typography>
          <Select
            className={Style.inField}
            IconComponent={(props) => (
              <IconButton {...props} style={{ padding: '4px 10px' }}>
                <img src={IconChevronDown} alt='IconChevronDown' />
              </IconButton>
            )}
            defaultValue={formValues?.jobType}
            size='small'
            name='jobType'
            value={formValues?.jobType}
            error={!!errors?.jobType}
            onChange={(e) => {
              handleFormChange(e)
            }}
          >
            <MenuItem value={'Part-time'}>Part-Time</MenuItem>
            <MenuItem value={'Permanent'}>Permanent</MenuItem>
          </Select>
          {!!errors?.jobType && (
            <FormHelperText error>{errors?.jobType}</FormHelperText>
          )}
        </Box>
      </Box>
      <div className={Style.dottedLine} />
      <Box component='div' className={Style.titleText}>
        Role & Permission
      </Box>
      <Box className={Style.flexSpaceBox}>
        <Box className={Style.halfWidth}>
          <Typography className={Style.textFieldWrapper}>
            Role Name
            <Typography className={Style.FF002E}>*</Typography>
          </Typography>
          {rolelist.find(
            (value: { uuid: string }) => value?.uuid === formValues?.roleUUID,
          )?.isDefault ? (
            <TextField
              fullWidth
              variant='outlined'
              className={Style.field}
              value={
                rolelist.find(
                  (value: { uuid: string }) =>
                    value?.uuid === formValues?.roleUUID,
                ).name
              }
              disabled
            />
          ) : (
            <Select
              className={Style.inField}
              IconComponent={(props) => (
                <IconButton {...props} style={{ padding: '4px 10px' }}>
                  <img src={IconChevronDown} alt='IconChevronDown' />
                </IconButton>
              )}
              defaultValue={formValues?.roleUUID}
              size='small'
              name='roleUUID'
              value={formValues?.roleUUID}
              error={!!errors?.roleUUID}
              onChange={(e) => {
                handleFormChange(e)
              }}
            >
              {rolelist
                ?.filter((value: { isDefault: boolean }) => !value?.isDefault)
                ?.map((role: { uuid: string; name: string }) => (
                  <MenuItem key={role?.uuid} value={role?.uuid}>
                    {role?.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          {!!errors?.roleUUID && (
            <FormHelperText error>{errors?.roleUUID}</FormHelperText>
          )}
        </Box>
      </Box>
      <Typography className={Style.textFieldWrapper}>
        Assigned Permission to this Role
      </Typography>
      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
        {rolelist
          ?.find((i: { uuid: string }) => i.uuid === formValues?.roleUUID)
          ?.permission?.map((i: string, index: number) => {
            return (
              <Box key={index} className={Style.smallGrayBox}>
                {i}
              </Box>
            )
          })}
      </Box>
      <div className={Style.dottedLine} />
      <Grid item className={Style.Action}>
        <Button
          variant='contained'
          className={Style.DivWrapper}
          onClick={onDiscard}
          disabled={disableButton}
        >
          <Typography className={Style.TextWrapper4}>Discard</Typography>
        </Button>
        <Button
          onClick={handleSubmit}
          variant='contained'
          className={Style.DivWrapper}
          disabled={disableButton}
        >
          <Typography className={Style.TextWrapper4}>Save Changes</Typography>
        </Button>
      </Grid>
    </Box>
  )
}

export default EditDetails
