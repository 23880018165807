import {
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useEffect, useState } from 'react'
import remove from 'src/Assets/Icons/remove.svg'
import { Calander, CommonDropdown, Name, StarText } from 'src/Components/Fields'
import Address from 'src/Components/Fields/Address'
import Style from './index.module.scss'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'

interface pastSignificantOthers {
  spouseFirstName: string
  spouseLastName: string
  spouseDateOfBirth: string
  spouseSSN: string
  typeOfMarriage: string
  dateOfMarriage: string
  marriageEndDate: string
  howMarriageEnded: string
  placeOfMarriage: {
    city: string
    state: string
    other: string
    country: string
  }
  placeOfMarriageEnded: {
    city: string
    state: string
    other: string
    country: string
  }
  pastSOEndInDeath: string
  pastSODeathDate: string
}

const ProfileBox = ({
  errors,
  formValues,
  addressHandler,
  changeHandler,
  handleRemove,
  index,
  setFormValues,
}: {
  errors: Record<string, string>
  formValues: pastSignificantOthers
  addressHandler: (data: {
    parent: 'placeOfMarriage' | 'placeOfMarriageEnded'
    name: string
    value: string
  }) => void
  changeHandler: (data: { name: string; value: string | number }) => void
  handleRemove: () => void
  index: number
  setFormValues: (data: {
    name: string
    value: typeof formValues.placeOfMarriage
  }) => void
}) => {
  const assistant = HasAssistant()
  const [SSN, setSSN] = useState<string[]>(['', '', ''])
  const handleSSNChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.match(/^\d*$/)) {
        const newSSN = [...SSN]
        newSSN[index] = event.target.value
        setSSN(newSSN)
        changeHandler({
          name: 'spouseSSN',
          value: `${newSSN[0]}${newSSN[1]}${newSSN[2]}`,
        })
      }
    }

  // useEffect(() => {
  //   changeHandler({
  //     name: 'spouseSSN',
  //     value: SSN.join(''),
  //   })
  // }, [SSN])

  useEffect(() => {
    setSSN([
      formValues?.spouseSSN?.slice(0, 3),
      formValues?.spouseSSN?.slice(3, 5),
      formValues?.spouseSSN?.slice(5),
    ])
  }, [formValues])

  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>Spouse {index + 1}</Typography>
          {index > 0 && (
            <img
              src={remove}
              alt=''
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleRemove()
              }}
            />
          )}
        </Grid>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Name
            name='spouseFirstName'
            value={formValues?.spouseFirstName}
            title="Prior Spouse's First Name"
            handleChange={changeHandler}
            error={errors?.spouseFirstName}
          />
          <Name
            name='spouseLastName'
            value={formValues?.spouseLastName}
            title="Prior Spouse's Last Name"
            handleChange={changeHandler}
            error={errors?.spouseLastName}
          />
        </Grid>
        <Calander
          name='spouseDateOfBirth'
          value={formValues?.spouseDateOfBirth}
          title="Prior Spouse's Date of Birth (estimate if unknown)"
          handleChange={changeHandler}
          error={errors?.spouseDateOfBirth}
        />
        <StarText
          text={`This information will not be shared and will be safeguarded 24/7.`}
        />

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Social Security Number (SSN)
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <Grid gap={1} display={'flex'} flexDirection={'row'}>
              <TextField
                fullWidth
                className={Style.AnsTxt}
                value={SSN[0]}
                onChange={handleSSNChange(0)}
                placeholder='###'
                inputProps={{ maxLength: 3 }}
                error={!!errors?.spouseSSN}
              />
              <TextField
                fullWidth
                className={Style.AnsTxt}
                value={SSN[1]}
                onChange={handleSSNChange(1)}
                placeholder='##'
                inputProps={{ maxLength: 2 }}
                error={!!errors?.spouseSSN}
              />
              <TextField
                fullWidth
                className={Style.AnsTxt}
                value={SSN[2]}
                onChange={handleSSNChange(2)}
                placeholder='####'
                inputProps={{ maxLength: 4 }}
                error={!!errors?.spouseSSN}
              />
            </Grid>
            <FormHelperText sx={{ mx: 1.5 }}>
              {errors?.spouseSSN}
            </FormHelperText>
          </Grid>
          <CommonDropdown
            name='typeOfMarriage'
            value={formValues?.typeOfMarriage}
            label='Type of Marriage'
            menulist={[
              {
                name: 'Marriage by clergy or public official',
                value: 'Marriage by clergy or public official',
              },
              {
                name: 'Other ceremonial marriage',
                value: 'Other ceremonial marriage',
              },
              { name: 'Common law marriage', value: 'Common law marriage' },
              {
                name: 'I do not know, or this information is not  available',
                value: 'I do not know, or this information is not available',
              },
            ]}
            handleChange={changeHandler}
            error={errors?.typeOfMarriage}
          />
        </Grid>
        <Calander
          name='dateOfMarriage'
          value={formValues?.dateOfMarriage}
          title='Date Marriage Began'
          handleChange={changeHandler}
          error={errors?.dateOfMarriage}
        />

        <Typography className={Style.label}>Place of Marriage</Typography>
        <Address
          errors={errors}
          formValues={formValues.placeOfMarriage}
          setFormValues={(data) => {
            setFormValues({
              name: 'placeOfMarriage',
              value: {
                ...formValues.placeOfMarriage,
                ...data,
              },
            })
          }}
          handleChange={(data) => {
            addressHandler({
              parent: 'placeOfMarriage',
              name: data.name,
              value: data.value,
            })
          }}
        />
        <Grid width={'100%'}>
          <InputLabel className={Style.label}>Other</InputLabel>
          <TextField
            fullWidth
            type='text'
            id='other'
            name='other'
            placeholder='Other'
            multiline
            rows={4}
            className={Style.AnsTxt}
            value={formValues?.placeOfMarriage.other}
            onChange={(e) => {
              addressHandler({
                parent: 'placeOfMarriage',
                name: e.target.name,
                value: e.target.value,
              })
            }}
            error={!!errors?.other}
            helperText={errors?.other}
          />
        </Grid>
        <Calander
          name='marriageEndDate'
          value={formValues?.marriageEndDate}
          title='Date Marriage Ended (estimate if unknown)'
          minDate={formValues?.dateOfMarriage}
          handleChange={changeHandler}
          error={errors?.marriageEndDate}
        />
        <Box>
          <Typography className={Style.label}>Place Marriage Ended</Typography>
          <Address
            errors={errors}
            formValues={formValues.placeOfMarriageEnded}
            setFormValues={(data) => {
              setFormValues({
                name: 'placeOfMarriageEnded',
                value: {
                  ...formValues.placeOfMarriageEnded,
                  ...data,
                },
              })
            }}
            handleChange={(data) => {
              addressHandler({
                parent: 'placeOfMarriageEnded',
                name: data.name,
                value: data.value,
              })
            }}
          />
        </Box>

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Other<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='other'
              name='other'
              placeholder='Other'
              multiline
              rows={4}
              className={Style.AnsTxt}
              value={formValues?.placeOfMarriageEnded.other}
              onChange={(e) => {
                addressHandler({
                  parent: 'placeOfMarriageEnded',
                  name: e.target.name,
                  value: e.target.value,
                })
              }}
              error={!!errors?.other2}
              helperText={errors?.other2}
            />
          </Grid>
        </Grid>
        <CommonDropdown
          name='howMarriageEnded'
          value={formValues?.howMarriageEnded}
          label='How did the marriage end?'
          menulist={[
            { name: 'Divorce', value: 'Divorce' },
            { name: 'Death', value: 'Death' },
            { name: 'Annulment', value: 'Annulment' },
            { name: 'Other', value: 'Other' },
          ]}
          handleChange={changeHandler}
          error={errors?.howMarriageEnded}
        />
        {formValues?.howMarriageEnded !== 'Death' && (
          <>
            <CommonRadioBox
              title={
                assistant
                  ? `Has applicant's prior spouse deceased after the marriage ended?`
                  : `Has your prior spouse deceased after the marriage ended?`
              }
              commonState={formValues?.pastSOEndInDeath}
              setcommonState={(value) => {
                changeHandler({
                  name: 'pastSOEndInDeath',
                  value,
                })
              }}
              YesTitle={`My prior spouse has deceased after the marriage ended`}
              NoTitle={`My prior spouse has not deceased after the marriage ended`}
              error={errors.pastSOEndInDeath}
            />
            {formValues?.pastSOEndInDeath && (
              <Calander
                name='pastSODeathDate'
                value={formValues?.pastSODeathDate}
                title='Date of Death'
                minDate={formValues?.dateOfMarriage}
                handleChange={changeHandler}
                error={errors?.pastSODeathDate}
              />
            )}
          </>
        )}
      </Box>
    </>
  )
}
export default ProfileBox
