import { Box, Button, InputLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import { AddButton, ApplicationIntroBox, StarText } from 'src/Components/Fields'
import {
  AddressType,
  EmployerDetailsType,
} from 'src/Redux/Services/Types/applicationType'
import { EditEmployerDetails } from 'src/Redux/Slices/ApplicationSlices/EducationAndEmploymentSlice'
import { setEmployerDetails } from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../index.module.scss'
import EmployerForm from './EmployerForm'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { HasAssistant } from '../..'

const EmployerDetails = ({
  chatForm,
  handleNext,
  handleBack,
}: {
  chatForm: any
  handleNext: () => void
  handleBack: () => void
}) => {
  const assistant = HasAssistant()
  const dispatch = useAppDispatch()

  const storeValues = useAppSelector(
    (state) =>
      state?.applicationStore?.EducationAndEmployement?.EmployerDetails,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const educationId = useAppSelector(
    (state) =>
      state?.applicationStore?.ApplicationId?.EducationAndEmployementId,
  )
  const [formValues, setFormValues] = useState<EmployerDetailsType>({
    educationId,
    employerDetail: [
      {
        address: {
          city: '',
          state: '',
          country: '',
          postalCode: '',
          streetAddress: '',
        },
        averageDaysPerWeek: 0,
        averageHourPerDay: 0,
        companyName: '',
        jobTitle: '',
        lastPhysicallyWorkedDate: '',
        payAmountPerPayPeriod: 0,
        payFrequency: '',
        startDate: '',
        endDate: '',
        isphysicallyWorkedLastDate: undefined,
        typeOfBusiness: '',
      },
    ],
  })
  const [errors, setErrors] = useState<
    Record<string, Array<Record<string, string>>>
  >({})
  const [isPresent, setIsPresent] = useState<boolean[]>([false])

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({ ...storeValues, educationId })
    // setIsPresent(storeValues?.isPresent ?? [false])
    setIsPresent(
      storeValues.employerDetail?.map(
        (item: { endDate: string }) => !item.endDate,
      ),
    )
    setErrors({})
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            isphysicallyWorkedLastDate: formValues?.employerDetail.map(
              (item) => {
                return {
                  isphysicallyWorkedLastDate:
                    item?.isphysicallyWorkedLastDate === undefined
                      ? 'Boolean'
                      : item?.isphysicallyWorkedLastDate,
                }
              },
            ),
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])
  useEffect(() => {
    const newErrors: Record<string, Array<Record<string, string>>> = {
      ...errors,
    }
    if (
      formValues?.employerDetail !== undefined &&
      formValues?.employerDetail?.length !== 0 &&
      newErrors.employerDetail !== undefined
    ) {
      for (let i = 0; i < formValues?.employerDetail?.length; i++) {
        newErrors.employerDetail = [...newErrors.employerDetail]

        if (formValues?.employerDetail[i].address?.streetAddress) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index) return { ...error, streetAddress: '' }
              return error
            },
          )
        }
        if (formValues?.employerDetail[i].address?.city) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index) return { ...error, city: '' }
              return error
            },
          )
        }
        if (formValues?.employerDetail[i].address?.state) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index) return { ...error, state: '' }
              return error
            },
          )
        }
        if (formValues?.employerDetail[i].address?.postalCode) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index) return { ...error, postalCode: '' }
              return error
            },
          )
        }
        if (formValues?.employerDetail[i].address?.country) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index) return { ...error, country: '' }
              return error
            },
          )
        }
      }
      setErrors(newErrors)
    }
  }, [formValues.employerDetail])

  // const handleChange = (data: { name: string; value?: string | boolean }) => {
  //   setFormValues({ ...formValues, [data.name]: data.value })
  //   setErrors({ ...errors, [data.name]: '' })
  // }

  const handleFormChange = (
    index: number,
    data: { name: string; value?: string | boolean | number | AddressType },
  ) => {
    const newFormValues = formValues?.employerDetail
      ? [...formValues?.employerDetail]
      : []
    newFormValues[index] = { ...newFormValues[index], [data.name]: data.value }
    setFormValues({ ...formValues, employerDetail: newFormValues })
    const newerrors = errors?.employerDetail ? [...errors?.employerDetail] : []
    newerrors[index] = { ...newerrors[index], [data.name]: '' }
    setErrors({ ...errors, employerDetail: newerrors })
  }

  const handleAddEmployerForm = () => {
    const newFormValues = formValues?.employerDetail
      ? [...formValues?.employerDetail, { address: {} }]
      : [{ address: {} }]
    setFormValues({ ...formValues, employerDetail: newFormValues })
    setIsPresent([...isPresent, false])
  }
  const handleRemoveEmployerForm = (index: number) => {
    const newFormValues = formValues.employerDetail?.filter(
      (_, i) => i !== index,
    )
    setFormValues({ ...formValues, employerDetail: newFormValues })
    setIsPresent([...isPresent.slice(0, index), ...isPresent.slice(index + 1)])
  }

  const validation = () => {
    let isValid = true
    const newErrors: Record<string, Array<Record<string, string>>> = {
      employerDetail: [{}],
    }
    const currentDate = new Date().toISOString().split('T')[0]
    if (
      formValues?.employerDetail !== undefined &&
      formValues?.employerDetail?.length !== 0 &&
      newErrors.employerDetail !== undefined
    ) {
      for (let i = 0; i < formValues?.employerDetail?.length; i++) {
        newErrors.employerDetail = [...newErrors.employerDetail, {}]
        if (!formValues?.employerDetail[i].companyName?.trim()) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return { ...error, companyName: 'Company Name is required' }
              return error
            },
          )
          isValid = false
        }
        if (!formValues?.employerDetail[i].jobTitle?.trim()) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return { ...error, jobTitle: 'Job Title is required' }
              return error
            },
          )
          isValid = false
        }
        if (!formValues?.employerDetail[i].payAmountPerPayPeriod) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return {
                  ...error,
                  payAmountPerPayPeriod: 'Pay Amount is required',
                }
              return error
            },
          )
          isValid = false
        }
        if (!formValues?.employerDetail[i].payFrequency) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return { ...error, payFrequency: 'Pay Frequency is required' }
              return error
            },
          )
          isValid = false
        }
        if (!formValues?.employerDetail[i].startDate) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return { ...error, startDate: 'Start Date is required' }
              return error
            },
          )
          isValid = false
        }
        if (formValues?.employerDetail[i].startDate > currentDate) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return {
                  ...error,
                  startDate: 'Start Date cannot be in the future',
                }
              return error
            },
          )
          isValid = false
        }
        if (
          formValues?.employerDetail[i].isphysicallyWorkedLastDate === undefined
        ) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return {
                  ...error,
                  isphysicallyWorkedLastDate:
                    'Is physically worked last date is required',
                }
              return error
            },
          )
          isValid = false
        }
        if (
          formValues?.employerDetail[i].isphysicallyWorkedLastDate > currentDate
        ) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return {
                  ...error,
                  isphysicallyWorkedLastDate:
                    'Is physically worked last date cannot be in the future',
                }
              return error
            },
          )
          isValid = false
        }
        if (!formValues?.employerDetail[i].typeOfBusiness) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return {
                  ...error,
                  typeOfBusiness: 'Type of business is required',
                }
              return error
            },
          )
          isValid = false
        }
        if (!formValues?.employerDetail[i].averageDaysPerWeek) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return {
                  ...error,
                  averageDaysPerWeek: 'Average days per week is required',
                }
              return error
            },
          )
          isValid = false
        }
        if (!formValues?.employerDetail[i].averageHourPerDay) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return {
                  ...error,
                  averageHourPerDay: 'Average hour per day is required',
                }
              return error
            },
          )
          isValid = false
        }
        if (!isPresent[i]) {
          if (!formValues?.employerDetail[i].endDate) {
            newErrors.employerDetail = newErrors.employerDetail?.map(
              (error, index) => {
                if (i === index)
                  return { ...error, endDate: 'End Date is required' }
                return error
              },
            )
            isValid = false
          }
          if (formValues?.employerDetail[i].endDate > currentDate) {
            newErrors.employerDetail = newErrors.employerDetail?.map(
              (error, index) => {
                if (i === index)
                  return {
                    ...error,
                    endDate: 'End Date cannot be in the future',
                  }
                return error
              },
            )
            isValid = false
          }
          if (
            formValues?.employerDetail[i].isphysicallyWorkedLastDate ===
              false &&
            !formValues?.employerDetail[i].lastPhysicallyWorkedDate
          ) {
            newErrors.employerDetail = newErrors.employerDetail?.map(
              (error, index) => {
                if (i === index)
                  return {
                    ...error,
                    lastPhysicallyWorkedDate:
                      'Last physically worked date is required',
                  }
                return error
              },
            )
            isValid = false
          }
          if (
            formValues?.employerDetail[i].lastPhysicallyWorkedDate > currentDate
          ) {
            newErrors.employerDetail = newErrors.employerDetail?.map(
              (error, index) => {
                if (i === index)
                  return {
                    ...error,
                    lastPhysicallyWorkedDate:
                      'Last physically worked date cannot be in the future',
                  }
                return error
              },
            )
            isValid = false
          }
        }
        if (!formValues?.employerDetail[i].address?.streetAddress) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return { ...error, streetAddress: 'Street Address is required' }
              return error
            },
          )
          isValid = false
        }
        if (!formValues?.employerDetail[i].address?.city) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index) return { ...error, city: 'City is required' }
              return error
            },
          )
          isValid = false
        }
        if (!formValues?.employerDetail[i].address?.state) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index) return { ...error, state: 'State is required' }
              return error
            },
          )
          isValid = false
        }
        if (!formValues?.employerDetail[i].address?.postalCode) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return { ...error, postalCode: 'Zip code is required' }
              return error
            },
          )
          isValid = false
        }
        if (!formValues?.employerDetail[i].address?.country) {
          newErrors.employerDetail = newErrors.employerDetail?.map(
            (error, index) => {
              if (i === index)
                return { ...error, country: 'Country is required' }
              return error
            },
          )
          isValid = false
        }
      }
    }

    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validation()) {
      const payload: EmployerDetailsType = { employerDetail: [] }
      payload.educationId = formValues.educationId

      if (
        formValues?.employerDetail !== undefined &&
        payload?.employerDetail !== undefined
      ) {
        for (let i = 0; i < formValues?.employerDetail?.length; i++) {
          if (payload.employerDetail?.length)
            payload.employerDetail = [
              ...payload.employerDetail,
              { address: {} },
            ]
          else payload.employerDetail = [{ address: {} }]
          payload.employerDetail[i].companyName =
            formValues?.employerDetail[i].companyName
          payload.employerDetail[i].jobTitle =
            formValues?.employerDetail[i].jobTitle
          payload.employerDetail[i].payAmountPerPayPeriod =
            formValues?.employerDetail[i].payAmountPerPayPeriod
          payload.employerDetail[i].payFrequency =
            formValues?.employerDetail[i].payFrequency
          payload.employerDetail[i].startDate =
            formValues?.employerDetail[i].startDate
          payload.employerDetail[i].isphysicallyWorkedLastDate =
            formValues?.employerDetail[i].isphysicallyWorkedLastDate
          payload.employerDetail[i].typeOfBusiness =
            formValues?.employerDetail[i].typeOfBusiness
          payload.employerDetail[i].averageDaysPerWeek =
            formValues?.employerDetail[i].averageDaysPerWeek
          payload.employerDetail[i].averageHourPerDay =
            formValues?.employerDetail[i].averageHourPerDay
          payload.employerDetail[i].address.streetAddress =
            formValues?.employerDetail[i].address?.streetAddress
          payload.employerDetail[i].address.city =
            formValues?.employerDetail[i].address?.city
          payload.employerDetail[i].address.state =
            formValues?.employerDetail[i].address?.state
          payload.employerDetail[i].address.postalCode =
            formValues?.employerDetail[i].address?.postalCode
          payload.employerDetail[i].address.country =
            formValues?.employerDetail[i].address?.country
          if (!isPresent[i]) {
            payload.employerDetail[i].endDate =
              formValues?.employerDetail[i].endDate
            payload.employerDetail[i].lastPhysicallyWorkedDate =
              formValues?.employerDetail[i].lastPhysicallyWorkedDate
          }
        }
      }
      dispatch(setEmployerDetails({ ...formValues, isPresent }))
      dispatch(EditEmployerDetails(payload)).then((res) => {
        if (res?.payload?.success) {
          handleNext()
        }
      })
    }
  }

  return (
    <>
      <Box className={Style.DialogContentBox}>
        <Box className={Style.GridBox}>
          <form className={Style.Form} onSubmit={handleSubmit}>
            <ApplicationIntroBox heading="Great job! Now, we're going to focus on current and past employers." />
            <StarText
              text={[
                `IMPORTANT:`,
                `It is essential that ${assistant ? `applicant` : `you`} provide a complete employment history in order for ${assistant ? `applicant` : `you`} to receive maximum disability benefits. Understanding ${assistant ? `applicant's` : `your`} work history allows the BenefitsBoost team and ${assistant ? `applicant's` : `your`} legal team to properly assess whether ${assistant ? `applicant` : `you`} are eligible for Social Security Disability Income (SSDI) and/or Supplemental Security Income (SSI) benefits. The more employment information we have, the more likely ${assistant ? `applicant` : `you`} are eligible for both benefits.`,
              ]}
            />
            <InputLabel className={Style.label}>
              Let's start by adding the details for{' '}
              {assistant ? `applicant's` : `your`} latest (or current) job.
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            {formValues?.employerDetail?.map((values, index) => (
              <EmployerForm
                formValues={formValues}
                values={values}
                setFormValues={setFormValues}
                key={index}
                index={index}
                handleChange={(data) => {
                  handleFormChange(index, data)
                }}
                handleRemove={() => {
                  handleRemoveEmployerForm(index)
                }}
                isPresent={isPresent[index]}
                setIsPresent={(value) => {
                  setIsPresent(
                    isPresent.map((val, i) => (i === index ? value : val)),
                  )
                }}
                errors={errors.employerDetail?.[index] ?? {}}
              />
            ))}
            <AddButton
              title={`Provide more information for each of ${assistant ? 'applicant‘s' : 'your'} job`}
              subTitle={
                assistant
                  ? `For comprehensive representation of applicant's situation, It is important that all
                    service be shown below`
                  : `For comprehensive representation of your situation, It is important that all
                    service be shown below`
              }
              button='Add Employer'
              onClick={handleAddEmployerForm}
            />
            <Box className={Style.AddButton}>
              <Button
                variant='contained'
                className={Style.ButtonOne}
                onClick={handleBack}
              >
                Previous
              </Button>
              <Button
                variant='contained'
                type='submit'
                className={Style.ButtonTwo}
              >
                Next
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default EmployerDetails
