import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button, InputLabel } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import {
  ApplicationIntroBox,
  CommonDropdown,
  Name,
  StarText,
} from 'src/Components/Fields'
import { AddReceivingDisability } from 'src/Redux/Slices/ApplicationSlices/LegalDetailsSlice'
import { setReceivingDisability } from 'src/Redux/Slices/ApplicationStore/LegalDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ROUTES } from 'src/Utils/Routes'
import Style from './index.module.scss'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { HasAssistant } from '../..'

interface ReceivingDisabilityType {
  legalId: string | undefined
  isHavingBankAccount: boolean | undefined
  accountType: string
  routingNumber: string
  accountNumber: string
}

const ReceivingDisability = ({
  handleBack,
  handleNext,
  chatForm,
}: {
  chatForm: any
  setLegalId?: (value: string) => void
  handleBack: () => void
  handleNext: () => void
}) => {
  const assistant = HasAssistant()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const ReceivingDisabilityState = useAppSelector(
    (state) => state?.applicationStore?.LegalDetails?.ReceivingDisability,
  )
  const legalId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.legalId,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )

  const [formValues, setFormValues] = useState<ReceivingDisabilityType>({
    legalId,
    isHavingBankAccount: undefined,
    accountType: '',
    routingNumber: '',
    accountNumber: '',
  })

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )

  useEffect(() => {
    setFormValues({
      ...ReceivingDisabilityState,
    })
  }, [])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            isHavingBankAccount: formValues.isHavingBankAccount
              ? formValues.isHavingBankAccount
              : 'Boolean',
          },
        }),
      )
  }, [isBotOpen, legalId])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (data: {
    name: string
    value: string | number
  }) => {
    const { name, value } = data
    setFormValues({ ...formValues, [name]: value })
    // You may perform validation here and update errors state accordingly
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}
    if (formValues?.isHavingBankAccount === undefined) {
      newErrors.isHavingBankAccount = 'Please select an option'
      valid = false
    }
    if (formValues?.isHavingBankAccount) {
      // Validation logic for Full Name
      if (!formValues.accountType.trim()) {
        newErrors.accountType = 'Account Type is required'
        valid = false
      }
      if (!formValues.routingNumber.trim()) {
        newErrors.routingNumber = 'Routing Number is required'
        valid = false
      } else if (formValues.routingNumber.length < 9) {
        newErrors.routingNumber =
          'Routing Number should be at least 9 digits long'
        valid = false
      }
      if (!formValues.accountNumber.trim()) {
        newErrors.accountNumber = 'Account Number is required'
        valid = false
      } else if (formValues.accountNumber.length < 6) {
        newErrors.accountNumber =
          'Account Number should be at least 6 digits long'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Conditionally construct the object to be passed based on haveUsedOtherName value
    const formValuesToSubmit =
      formValues.isHavingBankAccount === true
        ? formValues
        : {
            legalId,
            isHavingBankAccount: formValues.isHavingBankAccount,
          }

    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(setReceivingDisability(formValues))
      dispatch(AddReceivingDisability(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            navigate(ROUTES.DISABILITY_DETAILS)
          }
        },
      )
    }
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox
            heading={`Let's discuss the best way for ${assistant ? `applicant` : `you`} to receive your disability payments`}
          />

          {/* Common radio */}
          <CommonRadioBox
            error={errors?.isHavingBankAccount}
            title={
              assistant
                ? 'Does applicant have a bank account that applicant can use to receive direct deposit payments?'
                : 'Do you have a bank account that you can use to receive direct deposit payments?'
            }
            commonState={formValues?.isHavingBankAccount}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isHavingBankAccount: value,
              })
            }}
            YesTitle='I have a bank account that I can use to receive direct deposit payments'
            NoTitle='I do not have a bank account that I can use to receive direct deposit payments'
          />

          {formValues?.isHavingBankAccount ? (
            <>
              <StarText text='You must be an owner or co-owner of this account.' />

              <Box my={'10px'}>
                <InputLabel className={Style.label}>
                  Great! Now, let's gather some details so that we can make sure
                  {assistant ? `applicant's` : `your`} benefits are deposited
                  into the right account.
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <CommonDropdown
                    name='accountType'
                    label='Account Type'
                    value={formValues.accountType}
                    menulist={[
                      { name: 'Checking', value: 'Checking' },
                      { name: 'Savings', value: 'Savings' },
                    ]}
                    handleChange={handleFieldChange}
                    error={errors.accountType}
                  />
                  <Name
                    number
                    name='routingNumber'
                    value={formValues?.routingNumber}
                    title='Routing Number'
                    handleChange={handleFieldChange}
                    error={errors.routingNumber}
                  />
                </Grid>
                <Name
                  number
                  maxLength={15}
                  name='accountNumber'
                  value={formValues?.accountNumber}
                  title='Account Number'
                  handleChange={handleFieldChange}
                  error={errors.accountNumber}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleBack}
            >
              Previous
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Continue
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default ReceivingDisability
